import { action, makeObservable, observable } from "mobx";

export class PageStore {
  showMenu = false;
  showFavorite = false;
  showCollections = false;

  constructor() {
    makeObservable(this, {
      showMenu: observable,
      setShowMenu: action,
      showFavorite: observable,
      setShowFavorite: action,
      showCollections: observable,
      setShowCollections: action,
    });
  }

  setShowMenu = async (showMenu) => {
    this.showMenu = showMenu;
  };

  setShowFavorite = async (showFavorite) => {
    this.showFavorite = showFavorite;
  };

  setShowCollections = async (showCollections) => {
    this.showCollections = showCollections;
  };
}

export const pageStore = new PageStore();
