import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import "./Service.css";

export const Service = () => {
  return (
    <div className="settings__container">
      <div className="settings__containerBackground"></div>
      <div className="settings__title">&nbsp;Terms of Service</div>

      <div className="settings__paragraph">
        <span className="settings__bold">Last Updated: 01.09.2024</span>
      </div>

      <div className="settings__paragraph">
        <span>
          Welcome to Kinkograph. These Terms of Service (&quot;Terms&quot;)
          govern your access to and use of our website, products, and services
          (collectively, the &quot;Services&quot;). By accessing or using our
          Services, you agree to be bound by these Terms. If you do not agree to
          these Terms, please do not use our Services.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Acceptance of Terms</span>
          &nbsp;- By accessing or using our Services, you affirm that you are at
          least 18 years old (or the legal age of majority in your jurisdiction)
          and have the legal capacity to enter into these Terms. If you are
          using the Services on behalf of an organization, you agree to these
          Terms on behalf of that organization and represent that you have the
          authority to bind the organization to these Terms.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold"> Modifications to Terms</span>
          &nbsp;- We may modify these Terms from time to time. We will notify
          you of any significant changes by posting the updated Terms on our
          website and indicating the &quot;Last Updated&quot; date. Your
          continued use of the Services after the changes take effect
          constitutes your acceptance of the revised Terms.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Use of Services</span>
          &nbsp;- You agree to use the Services only for lawful purposes and in
          compliance with all applicable laws and regulations. You are
          responsible for any content you upload, post, or otherwise transmit
          through the Services. You agree not to:
          <ul>
            <li>
              Use the Services in any way that could harm, disable, overburden,
              or impair the Services.
            </li>
            <li>
              Attempt to gain unauthorized access to any part of the Services or
              to other users&apos; accounts.
            </li>
            <li>
              Upload, post, or transmit any content that is unlawful, harmful,
              defamatory, or otherwise objectionable.
            </li>
          </ul>
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">User Accounts </span>
          &nbsp;- To access certain features of the Services, you may need to
          create an account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate, current, and complete. You are
          responsible for safeguarding your account and any activities or
          actions under your account. We are not liable for any loss or damage
          arising from your failure to protect your account credentials.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Privacy</span>
          &nbsp;- Your privacy is important to us. Please review our [Privacy
          Policy] to understand how we collect, use, and protect your personal
          information. By using our Services, you consent to our collection and
          use of your information as described in the Privacy Policy.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Intellectual Property</span>
          &nbsp;- All content, trademarks, logos, and other intellectual
          property associated with the Services are owned by kinkograph or its
          licensors and are protected by copyright, trademark, and other
          intellectual property laws. You may not use, reproduce, distribute, or
          create derivative works of any content from the Services without our
          prior written consent.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold"> Payment Terms</span>
          &nbsp;- If you purchase any products or services through our website,
          you agree to pay all fees and charges, including applicable taxes, in
          accordance with the payment terms provided at the time of purchase. We
          reserve the right to modify our fees and payment terms at any time.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Termination</span>
          &nbsp;- We reserve the right to suspend or terminate your access to
          the Services at our sole discretion, without notice or liability, for
          any reason, including if we believe you have violated these Terms.
          Upon termination, your right to use the Services will immediately
          cease.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Disclaimers</span>
          &nbsp;- The Services are provided &quot;as is&quot; and &quot;as
          available&quot; without any warranties of any kind, either express or
          implied, including but not limited to, implied warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement. We do not warrant that the Services will be
          uninterrupted, secure, or error-free.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Limitation of Liability</span>
          &nbsp;- To the maximum extent permitted by law, Kinkograph shall not
          be liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, resulting from (a) your use or inability to use the
          Services; (b) any unauthorized access to or use of our servers and/or
          any personal information stored therein; (c) any interruption or
          cessation of transmission to or from the Services; (d) any bugs,
          viruses, trojan horses, or the like that may be transmitted to or
          through the Services by any third party; (e) any errors or omissions
          in any content or for any loss or damage incurred as a result of your
          use of any content posted, emailed, transmitted, or otherwise made
          available through the Services; and/or (f) the defamatory, offensive,
          or illegal conduct of any third party.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Indemnification</span>
          Indemnification You agree to indemnify and hold harmless Kinkograph,
          its affiliates, and their respective officers, directors, employees,
          and agents from and against any and all claims, damages, obligations,
          losses, liabilities, costs, or debt, and expenses (including
          attorney&apos;s fees) arising from: (a) your use of and access to the
          Services; (b) your violation of any term of these Terms; (c) your
          violation of any third-party right, including without limitation any
          intellectual property, privacy, or proprietary right; or (d) your
          violation of any law, rule, or regulation.
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Governing Law</span>
          &nbsp;- These Terms shall be governed by and construed in accordance
          with the laws of Berlin, Germany, without regard to its conflict of
          law principles. You agree to submit to the exclusive jurisdiction of
          the courts located in Berlin, Germany to resolve any legal matter
          arising from these Terms or your use of the Services.{" "}
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          <span className="settings__bold">Miscellaneous</span>
          <ul>
            <li>
              Entire Agreement: These Terms, together with our Privacy Policy
              and any additional terms to which you agree when using particular
              elements of the Services, constitute the entire agreement between
              you and Kinkograph concerning the Services.
            </li>
            <li>
              Severability: If any provision of these Terms is found to be
              invalid or unenforceable, the remaining provisions will remain in
              full force and effect.
            </li>
            <li>
              Waiver: No waiver of any term of these Terms shall be deemed a
              further or continuing waiver of such term or any other term, and
              Kinkograph&apos;s failure to assert any right or provision under
              these Terms shall not constitute a waiver of such right or
              provision.
            </li>
          </ul>
        </span>
      </div>

      <div className="settings__paragraph">
        <span>
          If you have any questions about these Terms, please contact us at
          support@kinkograph.com.
        </span>
      </div>

      <Link className="settings__returnLink" to="/">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};
