import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import {
  LockOutlined,
  UnlockOutlined,
  SettingOutlined,
  UserOutlined,
  ReconciliationOutlined,
  HeartOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";

import { userStore } from "../../../../store/userStore";
import { authStore } from "../../../../store/authStore";
import { pageStore } from "../../../../store/pageStore";

import "./Menu.css";

export const Menu = observer(() => {
  const [showOpenLock, setShowOpenLock] = useState(false);

  const handleClickLogOut = () => {
    authStore.logout();
    setTimeout(function () {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    if (pageStore.showMenu) {
      const elementBackground = document.getElementById("silentBackground");
      const elementContainer = document.getElementById("menuContainer");
      elementBackground.style.backdropFilter = "blur(7px) grayscale(25%)";
      elementContainer.style.opacity = 1;
    }
  });

  const handleHideMenu = () => {
    const elementBackground = document.getElementById("silentBackground");
    const elementContainer = document.getElementById("menuContainer");
    elementBackground.style.backdropFilter = "blur(0px) grayscale(0%)";
    elementContainer.style.opacity = 0;
    setTimeout(function () {
      pageStore.setShowMenu(false);
    }, 300);
  };

  return (
    <>
      <div
        className="menu__silentBackground"
        id="silentBackground"
        onClick={handleHideMenu}
      ></div>
      <div
        onMouseLeave={handleHideMenu}
        className="menu__Container"
        id="menuContainer"
      >
        <div
          className="link menu__element"
          onClick={() => pageStore.setShowFavorite(true)}
        >
          <HeartOutlined className="menu__elementIcon" />
          <span className="menu__elementTitle">Favorites</span>
        </div>
        <div
          className=" link menu__element"
          onClick={() => pageStore.setShowCollections(true)}
        >
          <AppstoreOutlined className="menu__elementIcon" />
          <span className="menu__elementTitle">Collections</span>
        </div>

        <div className="menu__elementDisabled">
          <UserOutlined className="menu__elementIcon" />
          <span className="menu__elementTitle">Profile</span>
        </div>
        <div className="menu__elementDisabled">
          <SettingOutlined className="menu__elementIcon" />
          <span className="menu__elementTitle">Settings</span>
        </div>

        <div className="menu__whiteline"></div>
        <div
          className="link menu__element"
          onMouseEnter={() => setShowOpenLock(true)}
          onMouseLeave={() => setShowOpenLock(false)}
          onClick={handleClickLogOut}
        >
          {showOpenLock ? (
            <UnlockOutlined className="menu__elementIcon" />
          ) : (
            <LockOutlined className="menu__elementIcon" />
          )}
          <span className="menu__elementTitle">Logout</span>
        </div>

        {userStore.isAdmin && (
          <>
            <div className="menu__whiteline"></div>
            <div className="link menu__element">
              <Link
                className="link menu__link"
                to="/admin/"
                onClick={handleHideMenu}
              >
                <ReconciliationOutlined className="menu__elementIcon" />
                <span className="menu__elementTitle">Admin</span>
                &nbsp;
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
});
