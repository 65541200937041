import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, LinkOutlined } from "@ant-design/icons";

import { PictureThumb } from "../PictureThumb/PictureThumb";
import { getPicturesFromId } from "../../store/calls/getPicturesFromId.js";

import "./CollectionsDrawer.css";

export const Collection = observer(({ collection }) => {
  const [picturesLoading, setPicturesLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pictures, setPictures] = useState(true);

  const deleteCollectionHandler = (id) => {
    console.log(id);
  };

  const fetchPicturesFromIds = async () => {
    setPicturesLoading(true);
    try {
      if (collection.pictures.length > 0) {
        const fetchedPictures = await getPicturesFromId(collection.pictures);
        setPictures(fetchedPictures);
      }
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setPicturesLoading(false);
  };

  useEffect(() => {
    fetchPicturesFromIds();
  }, []);

  return (
    <div
      className={"collectionsDrawer__collectionContainer"}
      key={`collection${collection.id}`}
    >
      <div className={"collectionsDrawer__collectionTitle"}>
        <div className={"collectionsDrawer__collectionTitleLeft"}>
          {collection.title}
        </div>
        <div className={"collectionsDrawer__collectionTitleRight"}>
          <Tooltip title={"Direct link to this collection"} placement="left">
            <LinkOutlined
              className={"collectionsDrawer__collectionTitleIcon"}
            />
          </Tooltip>
          <Popconfirm
            title="Sure to delete?"
            placement="left"
            onConfirm={() => deleteCollectionHandler(collection.id)}
          >
            <DeleteOutlined
              className={"collectionsDrawer__collectionTitleIcon"}
            />
          </Popconfirm>
        </div>
      </div>
      {picturesLoading ? (
        "loading"
      ) : (
        <div className="collectionsDrawer__main">
          {collection.pictures.map((pictureId, index) => {
            const picture = pictures.filter(
              (picture) => picture.id === pictureId,
            )[0];
            return (
              <div
                key={`coll${collection.id}pic${index}`}
                className="collectionsDrawer__picture"
              >
                <PictureThumb picture={picture} noHalo={true} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});
