import React, { useEffect, useState } from "react";
import {
  FullscreenOutlined,
  EyeInvisibleOutlined,
  LoadingOutlined,
  PlusOutlined,
  MinusOutlined,
  HeartOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react";
import { Tooltip, Dropdown, message } from "antd";
import { Link } from "react-router-dom";

import { pictureStore } from "../../store/pictureStore";
import { authStore } from "../../store/authStore";
import { favoriteStore } from "../../store/favoriteStore";
import { collectionStore } from "../../store/collectionStore";
import { getPictureUrl } from "../../helpers/picture/getPictureUrl.js";

import "./PictureThumb.css";

// TODO refactor contextMenu out

export const PictureThumb = observer((props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [s3SignedUrl, setS3SignedUrl] = useState(null);
  const isFavorite =
    favoriteStore.favoritesId.findIndex((id) => id === props.picture.id) >= 0;
  const { noHalo = false } = props;

  const loadImage = async () => {
    setIsLoading(true);
    // TODO: use createdBy as folder
    const url = await getPictureUrl(`1/${props.picture.key}_t`, "photos");
    try {
      await new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = url;
        loadImg.onload = () => resolve(url);
        loadImg.onerror = (err) => reject(err);
      });
    } catch (e) {
      console.log(e);
    }
    setS3SignedUrl(url);
    setIsLoading(false);
  };

  const mouseHoverHandler = (hover) => {
    const element = document.getElementById(`tag_${props.picture.id}`);
    const picture = document.getElementById(`pic_${props.picture.id}`);
    if (hover) {
      element.style.visibility = "visible";
      element.style.opacity = 1;
      picture.style.filter = "brightness(50%) grayscale(1)";
    } else {
      element.style.visibility = "hidden";
      element.style.opacity = 0;
      picture.style.filter = "brightness(100%) grayscale(0)";
    }
  };

  const clickHandler = () => {
    if (props.picture.adult_content && authStore.isGuest) {
      console.error("Guest can't see the adult picture in the gallery!");
      // Nothing
    } else {
      showContextMenu && showHideContextMenu();
      const index = pictureStore.allPictures.findIndex(
        (pic) => pic.id === props.picture.id,
      );
      if (index >= 0) {
        pictureStore.setShowOverlay(true);
        pictureStore.setSelected(index);
      } else {
        console.error("picture's index not found");
      }
    }
  };

  const handleAddRemovePicToCollection = (
    pictureIsInCollection,
    collectionId,
  ) => {
    showHideContextMenu();
    if (pictureIsInCollection) {
      collectionStore.deleteFromCollection(collectionId, props.picture.id);
      message.success({
        content: "Photo removed from collection",
        icon: <MinusOutlined />,
      });
    } else {
      collectionStore.addToCollection(collectionId, props.picture.id);
      message.success({
        content: "Photo added to collection",
        icon: <PlusOutlined />,
      });
    }
  };

  const handleAddRemovePicToFavorite = () => {
    showHideContextMenu();
    if (isFavorite) {
      favoriteStore.deleteFromFavoritesId(props.picture.id);
      message.error({
        content: "Photo removed from favorites",
        icon: <DeleteOutlined />,
      });
    } else {
      favoriteStore.addToFavoritesId(props.picture.id);
      message.success({
        content: "Photo added to favorites",
        icon: <HeartOutlined />,
      });
    }
  };

  const items = [
    {
      key: "header",
      disabled: true,
      label: (
        <div className={"picture__contextMenuTitle"}>
          <span className={"picture__contextMenuPicId"}>
            #{props.picture.id}
          </span>
          Add to a collection
          <span
            className={
              isFavorite
                ? "picture__contextMenuFavorited"
                : "picture__contextMenuNotFavorited"
            }
            onClick={handleAddRemovePicToFavorite}
          >
            ❤️
          </span>
        </div>
      ),
    },
  ];

  collectionStore.collections?.forEach((collection, index) => {
    const pictureIsInCollection =
      collection.pictures.find((id) => id === props.picture.id) >= 0;
    items.push({
      key: index,
      label: (
        <div
          onClick={() =>
            handleAddRemovePicToCollection(pictureIsInCollection, collection.id)
          }
        >
          {collection.title}
        </div>
      ),
      icon: pictureIsInCollection ? <MinusOutlined /> : <PlusOutlined />,
    });
  });

  if (items.length === 1) {
    // console.log("create new collection");
  }

  useEffect(() => {
    loadImage();
  }, []);

  const showHideContextMenu = (e) => {
    if (authStore.isGuest) {
      console.error("Guest can't use the context menu");
      // Nothing
    } else {
      const pictureElement = document.getElementById(
        `pictureContainer${props.picture.id}`,
      );
      const elementBackground = document.getElementById(
        `pictureSilentBackground${props.picture.id}`,
      );
      if (showContextMenu) {
        pictureElement.style.zIndex = 1;
        elementBackground.style.display = "none";
        elementBackground.style.backdropFilter = "blur(0px) grayscale(0%)";
        setShowContextMenu(false);
      } else {
        pictureElement.style.zIndex = 10;
        elementBackground.style.display = "block";
        elementBackground.style.backdropFilter = "blur(7px) grayscale(25%)";
        setShowContextMenu(true);
        e.preventDefault();
      }
    }
  };

  return (
    <>
      <div
        className="picture__silentBackground"
        id={`pictureSilentBackground${props.picture.id}`}
        onClick={showHideContextMenu}
      ></div>
      <Dropdown menu={{ items }} open={showContextMenu}>
        {isLoading ? (
          // TODO idealy the place holder should have the proportion of the final image
          <div className={"picture__container"}>
            <div className="picture__Loader">
              <LoadingOutlined style={{ fontSize: 36 }} spin />
            </div>
          </div>
        ) : (
          <div
            id={`pictureContainer${props.picture.id}`}
            className={`picture__container ${
              !!props.picture.adult_content && authStore.isGuest
                ? ""
                : "pointer"
            } `}
            onClick={clickHandler}
            onMouseEnter={() => mouseHoverHandler(true)}
            onMouseLeave={() => mouseHoverHandler(false)}
            onContextMenu={(e) => showHideContextMenu(e)}
          >
            <img
              id={`pic_${props.picture.id}`}
              className={`picture ${!props.picture.tags ? "picture__gray" : ""} ${props.picture.adult_content && authStore.isGuest ? "picture__blur" : ""} 
          ${isFavorite && !noHalo ? "halo" : ""} `}
              src={s3SignedUrl}
              alt={props.picture.id}
              key={props.picture.id}
            />
            <div className="picture__tagMissing">
              {!props.picture.tags && "TAGS MISSING"}
            </div>
            <div id={`tag_${props.picture.id}`} className="picture__tagShow">
              {props.picture.adult_content && authStore.isGuest ? (
                <Tooltip
                  title={
                    <span>
                      Create an{" "}
                      <Link to="/signup" style={{ color: "Goldenrod" }}>
                        account
                      </Link>{" "}
                      to see those
                    </span>
                  }
                >
                  <EyeInvisibleOutlined />
                  <div className="picture__adult">Adult content!</div>
                </Tooltip>
              ) : (
                <>
                  <FullscreenOutlined />
                  <div className="picture__id">#{props.picture.id}</div>
                </>
              )}
            </div>
          </div>
        )}
      </Dropdown>
    </>
  );
});
