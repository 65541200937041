import React, {
  useRef,
  useEffect,
  useCallback,
  Fragment,
  useState,
} from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { pictureStore } from "../../store/pictureStore";
import { pageStore } from "../../store/pageStore";
import { PictureThumb } from "../../component/PictureThumb/PictureThumb";
import { GalleryOverlay } from "../../component/GalleryOverlay/GalleryOverlay";
import { GalleryHeader } from "./GalleryHeader/GalleryHeader";
import { GalleryFooter } from "./GalleryFooter/GalleryFooter";
import { FavoritesDrawer } from "../../component/FavoritesDrawer/FavoritesDrawer";
import { CollectionsDrawer } from "../../component/CollectionsDrawer/CollectionsDrawer";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { authStore } from "../../store/authStore";
import { getGiveGuestAccess } from "../../store/calls/getGiveGuestAccess";
import { getCollection } from "../../store/calls/getCollection.js";
import { getPicturesFromId } from "../../store/calls/getPicturesFromId.js";

import "./Gallery.css";

export const Gallery = observer(() => {
  const throttling = useRef(false);
  const { tagInUrl, collectionId } = useParams();
  const [picturesCollection, setPictureCollection] = useState([]);

  useEffect(() => {
    if (pictureStore.galleryNeedsRefresh && !collectionId) {
      pictureStore.fetchPictures();
      pictureStore.setGalleryNeedsRefresh(false);
    }
  }, [pictureStore.galleryNeedsRefresh]);

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    document.addEventListener("scroll", scrollHandler);
    window.addEventListener("resize", resizeHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      document.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const scrollHandler = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.scrollHeight * 0.9
    ) {
      setTimeout(() => {
        pictureStore.nextPageLazyLoader();
      }, 500);
    }
  };

  const resizeHandler = () => {
    const galleryHeight = document?.getElementById("gallery-main");
    if (galleryHeight) {
      if (galleryHeight.offsetHeight < window.innerHeight) {
        pictureStore.nextPageLazyLoader();
      }
    }
  };

  const scroll = useCallback((direction) => {
    const eightyPerCentOfHeight = window.innerHeight * 0.8;
    const scrollPositionY = window.scrollY;
    let scrollToPositionY;
    if (direction === "down") {
      scrollToPositionY = scrollPositionY + eightyPerCentOfHeight;
    } else if (direction === "up") {
      scrollToPositionY = Math.max(scrollPositionY - eightyPerCentOfHeight, 0);
    }
    window.scroll({
      top: scrollToPositionY,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const keyDownHandler = useCallback(
    (event) => {
      const keyPressed = event.key.toLowerCase();
      if (
        keyPressed === "arrowright" ||
        keyPressed === "arrowleft" ||
        keyPressed === "arrowdown" ||
        keyPressed === "arrowup"
      ) {
        event.preventDefault();
        if (!pictureStore.showOverlay && !pictureStore.isTagInputActive) {
          if (throttling.current === false) {
            throttling.current = true;
            if (keyPressed === "arrowdown") {
              scroll("down");
            } else if (keyPressed === "arrowup") {
              scroll("up");
            }
            setTimeout(() => {
              throttling.current = false;
            }, 100);
          }
        }
      }
    },
    [scroll],
  );

  useEffect(() => {
    resizeHandler();
  });

  const giveGuestAccess = async () => {
    await getGiveGuestAccess();
    authStore.setIsGuest(true);
    authStore.setHasAccess(true);
  };

  const fetchCollection = async (id) => {
    try {
      const collection = await getCollection(id);
      const fetchedPictures = await getPicturesFromId(collection.pictures);
      setPictureCollection(fetchedPictures);
      pictureStore.setIsGalleryLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (tagInUrl) {
      if (!authStore.hasAccess) {
        giveGuestAccess();
      }
      pictureStore.setFilter([capitalizeFirstLetter(tagInUrl)]);
    } else if (collectionId) {
      fetchCollection(parseInt(collectionId));
    }
  }, [tagInUrl, collectionId]);

  return (
    <div className="gallery" id="gallery">
      <GalleryHeader tagInUrl={tagInUrl} hideFilter={collectionId} />
      {pageStore.showFavorite && <FavoritesDrawer />}
      {pageStore.showCollections && <CollectionsDrawer />}
      {pictureStore.isGalleryLoading ? (
        <div className="App__flex">
          <LoadingOutlined className="Gallery__spinner" />
          <div className="gallery__spinnerText">loading</div>
        </div>
      ) : (
        <Fragment>
          {pictureStore.showOverlay && <GalleryOverlay />}
          {picturesCollection.length ? (
            <div className="gallery__main" id="gallery-main">
              {picturesCollection.map((picture, index) => {
                return (
                  <div className="gallery__picture" key={index}>
                    <PictureThumb picture={picture} key={picture.id} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <div className="gallery__main" id="gallery-main">
                {pictureStore.allPictures.map((picture, index) => {
                  return (
                    <div className="gallery__picture" key={index}>
                      <PictureThumb picture={picture} key={picture.id} />
                    </div>
                  );
                })}
              </div>
              <GalleryFooter />
            </div>
          )}

          <FloatButton.BackTop />
        </Fragment>
      )}
    </div>
  );
});
