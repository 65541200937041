import { action, makeObservable, observable } from "mobx";

import { getCollections } from "./calls/getCollections.js";
import { patchCollection } from "./calls/patchCollection.js";

export class CollectionStore {
  loading = true;
  collections = [];
  selectedCollection = null;

  constructor() {
    makeObservable(this, {
      loading: observable,
      setLoading: action,
      collections: observable,
      setCollections: action,
      selectedCollection: observable,
      setSelectedCollection: action,
      fetchCollections: action,
      addToCollection: action,
      deleteFromCollection: action,
    });
  }

  setCollections = (collections) => {
    this.collections = collections;
  };

  setLoading = (loading) => {
    this.loading = loading;
  };

  setSelectedCollection = (selectedCollection) => {
    this.selectedCollection = selectedCollection;
  };

  fetchCollections = async () => {
    try {
      const fetchedCollections = await getCollections();
      this.setCollections(fetchedCollections);
    } catch (err) {
      console.log(err);
    }
    this.setLoading(false);
  };

  addToCollection = (collectionId, pictureId) => {
    const collectionIndex = this.collections.findIndex(
      (collection) => collectionId === collection.id,
    );
    const pictureIndex = this.collections[collectionIndex].pictures.findIndex(
      (id) => pictureId === id,
    );
    if (pictureIndex < 0) {
      this.collections[collectionIndex].pictures.push(pictureId);
    }
    patchCollection(collectionId, {
      pictures: this.collections[collectionIndex].pictures,
    });
  };

  deleteFromCollection = (collectionId, pictureId) => {
    const collectionIndex = this.collections.findIndex(
      (collection) => collectionId === collection.id,
    );
    const pictureIndex = this.collections[collectionIndex].pictures.findIndex(
      (id) => pictureId === id,
    );
    this.collections[collectionIndex].pictures.splice(pictureIndex, 1);
    patchCollection(collectionId, {
      pictures: this.collections[collectionIndex].pictures,
    });
  };
}

export const collectionStore = new CollectionStore();
