import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Drawer } from "antd";

import { pageStore } from "../../store/pageStore";
import { favoriteStore } from "../../store/favoriteStore";
import { PictureThumb } from "../PictureThumb/PictureThumb";

import "./FavoritesDrawer.css";

export const FavoritesDrawer = observer(() => {
  useEffect(() => {
    favoriteStore.fetchFavoriteObject();
  }, [favoriteStore.favoritesId]);

  return (
    <Drawer
      title={
        <span className="FavoritesDrawer__Title">
          Your {favoriteStore.favoritesId.length} favorite
          {favoriteStore.favoritesId.length > 1 && "s"}
        </span>
      }
      placement="left"
      closable={true}
      onClose={() => pageStore.setShowFavorite(false)}
      open={pageStore.showFavorite}
      width="100%"
    >
      {favoriteStore.favoriteObjectLoading ? (
        <div> Loading... </div>
      ) : (
        <div className="FavoritesDrawer__main">
          {favoriteStore.favoriteObject.map((picture, index) => {
            return (
              <div key={`favPic${index}`} className="FavoritesDrawer__picture">
                <PictureThumb picture={picture} noHalo={true} />
              </div>
            );
          })}
        </div>
      )}
    </Drawer>
  );
});
