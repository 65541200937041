import React from "react";
import { observer } from "mobx-react";
import { Drawer } from "antd";

import { pageStore } from "../../store/pageStore";
import { collectionStore } from "../../store/collectionStore";
import { Collection } from "./Collection";

import "./CollectionsDrawer.css";

export const CollectionsDrawer = observer(() => {
  return (
    <Drawer
      title={
        <span className="collectionsDrawer__Title">
          Your {collectionStore.collections.length} collection
          {collectionStore.collections.length > 1 && "s"}
        </span>
      }
      placement="left"
      closable={true}
      onClose={() => pageStore.setShowCollections(false)}
      open={pageStore.showCollections}
      width="100%"
    >
      {collectionStore.loading ? (
        <div> Loading... </div>
      ) : (
        collectionStore.collections.map((collection, index) => (
          <Collection collection={collection} key={`collection${index}`} />
        ))
      )}
    </Drawer>
  );
});
