import { action, makeObservable, observable } from "mobx";

import { getPicturesFromId } from "./calls/getPicturesFromId.js";
import { patchUserFavorites } from "./calls/patchUserFavorites.js";

export class FavoriteStore {
  favoritesId = [];
  favoriteObjectLoading = true;
  favoriteObject = [];

  constructor() {
    makeObservable(this, {
      favoriteObject: observable,
      setFavoriteObject: action,
      favoriteObjectLoading: observable,
      setFavoriteObjectLoading: action,
      fetchFavoriteObject: action,
      favoritesId: observable,
      addToFavoritesId: action,
      setFavoritesId: action,
      deleteFromFavoritesId: action,
    });
  }

  fetchFavoriteObject = async () => {
    try {
      if (this.favoritesId.length > 0) {
        const fetchedFavoriteObject = await getPicturesFromId(this.favoritesId);
        this.setFavoriteObject(fetchedFavoriteObject);
      }
    } catch (err) {
      console.log(err);
    }
    this.setFavoriteObjectLoading(false);
  };

  setFavoriteObject = (favoriteObject) => {
    this.favoriteObject = favoriteObject;
  };

  setFavoriteObjectLoading = (favoriteObjectLoading) => {
    this.favoriteObjectLoading = favoriteObjectLoading;
  };

  addToFavoritesId = (id) => {
    const index = this.favoritesId.findIndex((pictureId) => pictureId === id);
    if (index < 0) {
      this.favoritesId.push(id);
    }
    patchUserFavorites(this.favoritesId);
  };

  deleteFromFavoritesId = (id) => {
    const index = this.favoritesId.findIndex((pictureId) => pictureId === id);
    this.favoritesId.splice(index, 1);
    patchUserFavorites(this.favoritesId);
  };

  setFavoritesId = (favoritesId) => {
    this.favoritesId = favoritesId;
  };
}

export const favoriteStore = new FavoriteStore();
