import React, { useState } from "react";
import { notification, Button } from "antd";
import { useTranslation } from "react-i18next";

import { postLoginCode } from "../../store/calls/postLoginCode";
import { getGiveGuestAccess } from "../../store/calls/getGiveGuestAccess";
import { userStore } from "../../store/userStore";
import { authStore } from "../../store/authStore";
import { PinInput } from "../PinInput/PinInput";
import { LoginForm } from "./LoginForm";
import { SignUpForm } from "../SignUpForm/SignUpForm";
import { AlreadyMember } from "./AlreadyMember";

import "./Login.css";

export const Login = ({ signup, loginWithCode }) => {
  const [showSignUp, setShowSignUp] = useState(signup);
  const { t } = useTranslation();

  const checkPin = async (code) => {
    try {
      const res = await postLoginCode(code);
      if (res.status === 200) {
        if (res.data.id === "guest") {
          authStore.setIsGuest(true);
        } else {
          authStore.setIsGuest(false);
          userStore.fetchuserData();
        }
        authStore.setHasAccess(true);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: err.message,
        placement: "topLeft",
        className: "app__blackNotification",
      });
    }
  };

  const giveGuestAccess = async () => {
    await getGiveGuestAccess();
    authStore.setIsGuest(true);
    authStore.setHasAccess(true);
  };

  return (
    <div>
      <div className="login__container">
        {
          <div>
            {showSignUp ? (
              <SignUpForm setShowSignUp={setShowSignUp} />
            ) : (
              <>
                {loginWithCode ? <PinInput login={checkPin} /> : <LoginForm />}
                <div className="login__orContainer">
                  <div className="login__orLine"></div>
                  <div className="login__or">or</div>
                  <div className="login__orLine"></div>
                </div>
                <Button
                  type="primary"
                  className="login__guestAccessButton"
                  onClick={giveGuestAccess}
                >
                  {t("login.accessAsGuest")}
                </Button>
              </>
            )}
            <div className="login__alreadyMember">
              <AlreadyMember
                setShowSignUp={setShowSignUp}
                showSignUp={showSignUp}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};
